///*------------------------------------*\
//    #TRUMPS-SPACING
//\*------------------------------------*/

// Margin and padding helper classes. Use these to tweak layout on a micro
// level.
//
// `.(m|p)(t|r|b|l|h|v)(-|+|0) {}` = margin/padding top/right/bottom/left/horizontal/vertical less/more/none

$margin:    $base-spacing-unit !default;
$padding:   $base-spacing-unit !default;





// Margin helper classes.
//
// Add margins.
.m          { margin:           $margin !important; }
.mt         { margin-top:       $margin !important; }
.mr         { margin-right:     $margin !important; }
.mb         { margin-bottom:    $margin !important; }
.ml         { margin-left:      $margin !important; }
.mh         { margin-right:     $margin !important; margin-left:      $margin !important; }
.mv         { margin-top:       $margin !important; margin-bottom:    $margin !important; }

// Add small margins.
.m-         { margin:           $margin / 2 !important; }
.mt-        { margin-top:       $margin / 2 !important; }
.mr-        { margin-right:     $margin / 2 !important; }
.mb-        { margin-bottom:    $margin / 2 !important; }
.ml-        { margin-left:      $margin / 2 !important; }
.mh-        { margin-right:     $margin / 2 !important; margin-left:   $margin / 2 !important; }
.mv-        { margin-top:       $margin / 2 !important; margin-bottom: $margin / 2 !important; }

// Add medium margins.
.m\+        { margin:           $margin * 2 !important; }
.mt\+       { margin-top:       $margin * 2 !important; }
.mr\+       { margin-right:     $margin * 2 !important; }
.mb\+       { margin-bottom:    $margin * 2 !important; }
.ml\+       { margin-left:      $margin * 2 !important; }
.mh\+       { margin-right:     $margin * 2 !important; margin-left:   $margin * 2 !important; }
.mv\+       { margin-top:       $margin * 2 !important; margin-bottom: $margin * 2 !important; }

// Add large margins.
.m\+\+      { margin:           $margin * 3 !important; }
.mt\+\+     { margin-top:       $margin * 3 !important; }
.mr\+\+     { margin-right:     $margin * 3 !important; }
.mb\+\+     { margin-bottom:    $margin * 3 !important; }
.ml\+\+     { margin-left:      $margin * 3 !important; }
.mh\+\+     { margin-right:     $margin * 3 !important; margin-left:   $margin * 3 !important; }
.mv\+\+     { margin-top:       $margin * 3 !important; margin-bottom: $margin * 3 !important; }

// Add huge margins.
.m\+\+\+    { margin:           $margin * 4 !important; }
.mt\+\+\+   { margin-top:       $margin * 4 !important; }
.mr\+\+\+   { margin-right:     $margin * 4 !important; }
.mb\+\+\+   { margin-bottom:    $margin * 4 !important; }
.ml\+\+\+   { margin-left:      $margin * 4 !important; }
.mh\+\+\+   { margin-right:     $margin * 4 !important; margin-left:   $margin * 4 !important; }
.mv\+\+\+   { margin-top:       $margin * 4 !important; margin-bottom: $margin * 4 !important; }

// Remove margins.
.m0         { margin:           0 !important; }
.mt0        { margin-top:       0 !important; }
.mr0        { margin-right:     0 !important; }
.mb0        { margin-bottom:    0 !important; }
.ml0        { margin-left:      0 !important; }
.mh0        { margin-right:     0 !important; margin-left:      0 !important; }
.mv0        { margin-top:       0 !important; margin-bottom:    0 !important; }





// Padding helper classes.
//
// Add paddings.
.p          { padding:          $padding !important; }
.pt         { padding-top:      $padding !important; }
.pr         { padding-right:    $padding !important; }
.pb         { padding-bottom:   $padding !important; }
.pl         { padding-left:     $padding !important; }
.ph         { padding-right:    $padding !important; padding-left:    $padding !important; }
.pv         { padding-top:      $padding !important; padding-bottom:  $padding !important; }

// Add small paddings.
.p-         { padding:           $padding / 2 !important; }
.pt-        { padding-top:       $padding / 2 !important; }
.pr-        { padding-right:     $padding / 2 !important; }
.pb-        { padding-bottom:    $padding / 2 !important; }
.pl-        { padding-left:      $padding / 2 !important; }
.ph-        { padding-right:     $padding / 2 !important; padding-left:    $padding / 2 !important; }
.pv-        { padding-top:       $padding / 2 !important; padding-bottom:  $padding / 2 !important; }

// Add medium paddings.
.p\+        { padding:           $padding * 2 !important; }
.pt\+       { padding-top:       $padding * 2 !important; }
.pr\+       { padding-right:     $padding * 2 !important; }
.pb\+       { padding-bottom:    $padding * 2 !important; }
.pl\+       { padding-left:      $padding * 2 !important; }
.ph\+       { padding-right:     $padding * 2 !important; padding-left:   $padding * 2 !important; }
.pv\+       { padding-top:       $padding * 2 !important; padding-bottom: $padding * 2 !important; }

// Add large paddings.
.p\+\+      { padding:           $padding * 3 !important; }
.pt\+\+     { padding-top:       $padding * 3 !important; }
.pr\+\+     { padding-right:     $padding * 3 !important; }
.pb\+\+     { padding-bottom:    $padding * 3 !important; }
.pl\+\+     { padding-left:      $padding * 3 !important; }
.ph\+\+     { padding-right:     $padding * 3 !important; padding-left:    $padding * 3 !important; }
.pv\+\+     { padding-top:       $padding * 3 !important; padding-bottom:  $padding * 3 !important; }

// Add huge paddings.
.p\+\+\+    { padding:           $padding * 4 !important; }
.pt\+\+\+   { padding-top:       $padding * 4 !important; }
.pr\+\+\+   { padding-right:     $padding * 4 !important; }
.pb\+\+\+   { padding-bottom:    $padding * 4 !important; }
.pl\+\+\+   { padding-left:      $padding * 4 !important; }
.ph\+\+\+   { padding-right:     $padding * 4 !important; padding-left:    $padding * 4 !important; }
.pv\+\+\+   { padding-top:       $padding * 4 !important; padding-bottom:  $padding * 4 !important; }

// Remove paddings.
.p0         { padding:          0 !important; }
.pt0        { padding-top:      0 !important; }
.pr0        { padding-right:    0 !important; }
.pb0        { padding-bottom:   0 !important; }
.pl0        { padding-left:     0 !important; }
.ph0        { padding-right:    0 !important; padding-left:     0 !important; }
.pv0        { padding-top:      0 !important; padding-bottom:   0 !important; }