$brownish: #f1e8dc;
$brownish-font: #7d7264;

$darkgrey: #1b1e24;
$darkgrey-font: #8b91a0;

$lightgrey: #fcfcfc;
$lightgrey-font: #5a6170;

$cyanmax: #00a3d8;
$cyanmid: #22beef;
$cyanmin: #eaf9fe;
$cyan-font: darken($cyanmax, 25%);

$amethystmax: #9f45d8;
$amethystmid: #cd97eb;
$amethystmin: #f9f1fc;
$amethyst-font: #444;

$greenmax: #6f9e00;
$greenmid: #a2d200;
$greenmin: #f6f9e4;
$green-font: #444;

$orangemax: #ff9000;
$orangemid: #ffc100;
$orangemin: #fff9e9;
$orange-font: #444;

$redmax: #d90200;
$redmid: #ff4a43;
$redmin: #ffeced;
$red-font: #444;

$greensea: #16a085;
$dutch: #1693A5;
$hotpink: #FF0066;
$drank: #A40778;

$senutomax: #00a3d8;
$senutomid: #22beef;
$senutomin: #eaf9fe;
$senuto-font: #6d6f70;
$senuto-fontdark: #000000;
$senuto-bg: #f9f9f9;
$senuto-bglight: #ffffff;
$senuto-bgmid: #fcfcfc;
$senuto-orangemin: rgba(254, 120, 59, 1);
$senuto-orange: rgba(255, 105, 2, 1);
$senuto-dark: #30363E;