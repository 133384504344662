@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
       -o-border-radius: $radius;
          border-radius: $radius;
}

// Opacity
@mixin opacity($opacity) {
	// IE8 filter
	$opacity-ie: ($opacity * 100);
	filter: #{alpha(opacity=$opacity-ie)};
	opacity: $opacity;
}