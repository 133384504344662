///*------------------------------------*\
//    #BASE-TYPOGRAPHY
//\*------------------------------------*/

// Typography mixin
@mixin typography($type) {
    @if $type == 'caption' {
        @include font-size(12px);
        font-weight: 400;
        line-height: 20px;
    }

    @if $type == 'body-1' or $type == 'body-2' {
        @include media-query(portable) {
            @include font-size(14px);
        }

        @include media-query(desk) {
            @include font-size(13px);
        }
    }

    @if $type == 'body-1' {
        font-weight: 400;
        line-height: 20px;
    }

    @if $type == 'body-2' {
        font-weight: 500;
        line-height: 24px;
    }

    @if $type == 'subhead' {
        font-weight: 400;
        line-height: 24px;

        @include media-query(portable) {
            @include font-size(16px);
        }

        @include media-query(desk) {
            @include font-size(15px);
        }
    }

    @if $type == 'title' {
        @include font-size(20px);
        font-weight: 500;
        line-height: 28px;
    }

    @if $type == 'headline' {
        @include font-size(24px);
        font-weight: 400;
        line-height: 32px;
    }

    @if $type == 'display-1' {
        @include font-size(34px);
        font-weight: 400;
        line-height: 40px;
    }

    @if $type == 'display-2' {
        @include font-size(45px);
        font-weight: 400;
        line-height: 48px;
        letter-spacing: -1px;
    }

    @if $type == 'display-3' {
        @include font-size(56px);
        font-weight: 400;
        line-height: 64px;
        letter-spacing: -2px;
    }

    @if $type == 'display-4' {
        @include font-size(112px);
        font-weight: 300;
        line-height: 128px;
        letter-spacing: -5px;
    }
}





// Typography variants
.fs-caption,
%fs-caption {
    @include typography('caption');
}

.fs-body-1,
%fs-body-1 {
    @include typography('body-1');
}

.fs-body-2,
%fs-body-2 {
    @include typography('body-2');
}

.fs-subhead,
%fs-subhead {
    @include typography('subhead');
}

.fs-title,
%fs-title {
    @include typography('title');
}

.fs-headline,
%fs-headline {
    @include typography('headline');
}

.fs-display-1,
%fs-display-1 {
    @include typography('display-1');
}

.fs-display-2,
%fs-display-2 {
    @include typography('display-2');
}

.fs-display-3,
%fs-display-3 {
    @include typography('display-3');
}

.fs-display-4,
%fs-display-4 {
    @include typography('display-4');
}
