///*------------------------------------*\
//    #OBJECTS-DIALOG
//\*------------------------------------*/

// Dialog vars
$dialog-margin: $base-spacing-unit * 4;





// Dialog filter
.dialog-filter {
    @include position(fixed, 0 0 0 0);
    z-index: 999;
    background-color: rgba(0,0,0,0.6);
    opacity: 0;
    @include transition-property(opacity);
    @include transition-duration(0.6s);
    @include transition-timing-function($ease-out-quint);
}

.dialog-filter--is-shown {
    opacity: 1;
}





// Dialog base styles
.dialog {
    display: none;
    @include position(fixed, $dialog-margin null null 50%);
    z-index: 1000;
    background-color: $white;
    opacity: 0;
    @include z-depth(3);
    @include transform(translateY(-50px));
    @include transition-property(opacity, transform);
    @include transition-duration(0.6s);
    @include transition-timing-function($ease-out-quint);
}

.dialog--alert {
    width: 400px;
    margin-left: -200px;
}

.dialog--l {
    @include media-query(desk) {
        width: 800px;
        margin-left: -400px;
    }

    @include media-query(portable) {
        width: 80%;
        margin-left: -40%;
    }
}

.dialog--is-shown {
    opacity: 1;
    @include transform(translateY(0));
}

.dialog--is-fixed {
    bottom: $dialog-margin;

    .dialog__header {
        @include position(absolute, 0 0 null 0);
    }

    .dialog__scrollable {
        @include position(absolute, null 0 null 0);
        z-index: 1;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .dialog__actions {
        @include position(absolute, null 0 0 0);
        border-top: 1px solid $black-4;
    }
}

    // Dialog actions
    .dialog__actions {
        padding: $base-spacing-unit $base-spacing-unit * 2;
        text-align: right;
    }