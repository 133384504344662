@import "settings/common";

@mixin imgFilter($filter-type,$filter-amount) {
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}


/*** MATERIAL FIXES PRELUMX ***/
.material {
  body,
  h1, h2, h3, h4, h5, h6,
  p, blockquote, pre,
  dl, dd, ol, ul,
  form, fieldset, legend,
  figure,
  table, th, td, caption,
  hr {
    margin:  0;
    padding: 0;
  }
  .btn {
    padding-top: 0;
    padding-bottom:0;
  }

}

@import "source/vendors/lumx/dist/scss/lumx";


/*** SENUTO styles ***/

html {
  font-size:0.95em;
}

.mr--s{
 margin-right:1px;
}

.ml--s{
margin-left:1px;
}

.dropdown-link:not(.dropdown-link--is-header) i {
  min-width:24px;
}

.btn--icon.btn--grey:focus {
  background-color: rgba(158, 158, 158, 0.15);
}




.text-field__input {
  &.faviconed {
    padding-left: 0px;
    background-image: url('http://plus.google.com/_/favicon?domain=o');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    margin-left:15px;
  }
}

.text-field--symboled {

  &.text-field--is-active {
    .text-field__label {
      left:0px;
    }
  }
  .text-field__label {
    left:20px;
    //top:11px;
  }
  .text-field__input {
    padding-left:20px!important;
  }
}

.text-field__symbol {
  position: absolute;
  margin-top: 8px;
}

.dropdown-filter {
  display:none;
}

.lx-select__floating-label {
  top: -17px;
}

img {
  display:initial;
}

content-header {
  display: block;
  background-color: #E0E0E0;
  margin: -15px -40px 0 -30px;
  padding: 0 32px;
  transition: padding 0.2s ease-in;
  padding-bottom: $padding*2;
}



.fsm-sticky-header {
  padding: 0 32px;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.3);
}

content-header.fsm-sticky-header {
  padding-top:$padding*2;
  padding-bottom: 0;
}

.flag-icon {
  @include imgFilter(brightness, 93%);
}

body section {
  padding:1rem;
}

.clue--icon {
  font-size: 11px;
  position: absolute;
  margin-left: -8px;
  margin-top: 0px;
  color: #E0E0E0;
  transition: all 0.2s ease-in;
}

.clue--icon:hover {
  color: $grey-500;
}

.clued--wrapper {
  padding-right:11px;
}

.overlay {
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  /* margin-left: -15px; */
  z-index: 100000;
  top: 0;
  left: 0;

  .progress-container--circular {
    margin:10% auto 0 55%;
  }
}



/*** Bootstrap fixes ***/


.btn--s {
  padding-top:2px;
  padding-bottom:2px;
}

.btn--icon {
  box-shadow:none;
  &:hover, &:focus {
    box-shadow:none!important;
  }
}

.dropdown__menu--is-dropped {
  display: block;
  left:initial;
}

.lx-select__choices {
  margin-top: -12px;
  display: block;
  top: initial;
}

.bootstrap-dialog-message {
  word-break: break-all;
}

.radio-button__label {
  margin-bottom: 0;
}

/*** Minoral fixes ***/

.btn--flat {
  box-shadow:none!important;
}

.btn[class*="btn--m"]{
  padding-top:3px;
  padding-bottom:2px;
}




.card-container .card .front {
  background-color: transparent;
}

.card-container .card .back {
  border:none;
}


#navigation .dropdown-menu {
  opacity: 1;
}

.notifications .dropdown-menu, .settings .dropdown-menu {
  opacity:1;
  overflow:visible;
}

.dataTables_wrapper .dropdown-menu {
  opacity:1;

}

.dropdown-menu {
  z-index: 100000;
}

.tooltip, .notification {
  z-index: 100001;
}


.material {

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  background-color:transparent;
  border: none;
  font-size: inherit;
  padding: inherit;
  min-height: 32px;
  //height:initial;


  &:focus {
    background-color: transparent;
  }
}



  label {
    font-weight:400;
  }

  .btn {
    //box-shadow:none;
  }



  .btn--icon {
    border-radius:50%;
  }

  .btn--raised:hover {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084)!important;
  }

}
.search-filter__input {
  border:none!important;
}

.sf-form:last-child {
  padding: 26px 20px 13px;
}

.daterangepicker {
  opacity:1;
}

//.tooltip {
//  max-width:200px;
//  word-break:break-all;
//  white-space: normal;
//}

/*** LUMX Tables ***/

table.DTFC_Cloned tr {
  background-color:white;
}
div.DTFC_LeftBodyWrapper tbody tr td {
  border-right: 2px solid #DDDDDD;
}

.data-table-container {
  .text-field-help, .text-field-error {
    position: absolute;
  }
}

.data-table-header {
  border-bottom:none;

  .filters-container {
    padding: 0 16px 0 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.data-table__sortable-cell--asc:before, .data-table__sortable-cell--desc:before {
   margin-left: -16px;
 }

.dataTables_scrollBody th i {
  display: none;
}

div.DTFC_LeftBodyWrapper table {
  overflow: hidden !important;
}

.data-table-footer {
  @include display(flex);
  @include flex-direction(row);
  @include align-items(center);
  height: 64px;
  padding: 0 $base-spacing-unit * 2 0 $base-spacing-unit * 3;
  border-top: 1px solid $black-4;
}



.data-table-footer__label {
  @include flex(1);
}

.dataTables_scrollBody th:before {
  content: none;
  height: 0;
  overflow: hidden;
  line-height:0px;
}

.DTFC_Cloned .dataTables_scrollBody th:before {
  content: none;
}

.dataTables_scrollBody th {
  line-height:0px!important;
  overflow:hidden;
}

.data-table td {
  font-size: 0.95rem;
  height: 30px;
}

.data-table th, .data-table td {
  padding-right:36px;
}

td.change {
  font-size:13px;
  margin: 0 5px;
  i {
    position:absolute;
    display:block;
    font-size:17px;
    //margin-left:-13px;
    &.up {
      margin-top:13px;
    }
    &.down {
      margin-top:-13px;
    }
  }

}
.dataTables_lumx {

  th.condensed {
    padding: 4px 6px;
    font-size:11px;
  }

  td.condensed {
    padding: 4px 3px;
    font-size:11px;
    text-align:center;
  }


  label {
    margin-bottom:0px;
  }

  .dataTables_paginate {
    margin-right: -12px;
  }

  .data-table-footer {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .dataTables_length {
    font-size: 0.7rem;
    color: #5A5A5A;

    select {
      border: 1px solid white;
      box-shadow: none;
      color: #939393;
      cursor: pointer;
      width: 55px;
      display: inline-block;
    }
  }
  .dataTables_info {
    font-size: 0.7rem;
    padding-top: 0px;
    color: #5E5E5E;
    margin-left: 24px;
    margin-right: 12px;
  }
  div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 60px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  }

  .paginate_button i.mdi {
    font-size: 200%;
    line-height: 1.6;
    font-weight: bold;
  }

  tr.clickable {
    background-color: #FCFCFC;
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    box-shadow: 0px 1px 1px rgba(179, 179, 179, 0.6);
    &:hover {
      background-color: #EEEEEE;
      cursor: pointer;
    }
    &:active {
      background-color: #e4e4e4;
    }
    td {
      color: #005B79;
    }
  }

  td.dataTables_empty {
    padding-top: 18px;
  }

}

.data-table th {
  //padding-right:56px!important;
}

div .highcharts-legend-item .first-of-group{
  padding-top:20px;
  &:before {
    //content: 'github';
    position: absolute;
    top: -20px;
    left: -23px;
  }
}

.highcharts-container > svg {
  overflow:visible;
}

.dialog-filter {
  z-index: 1030;
}

.dialog {
  top: 172px;
  z-index: 1031;
}

.switch__label {
  min-height: 36px;
  vertical-align: bottom;
}

label.text-field__label {
  font-weight: 400;
}

.btn--flat.btn--white.btn--standout{
  background-color:rgba(255, 255, 255, 0.08);
  &:hover {
    background-color:rgba(255, 255, 255, 0.15);
  }
}

textarea.text-field__input {
  margin: 6px 0 2px 0;
}

.dropdown-link:hover {
  color:initial;
  text-decoration:none;
}

.radio-button__label:after {
  color: #FF6D00;
}

.switch__input:checked+.switch__label:after {
  background-color: #FF6D00;
}

.switch__input:checked+.switch__label:before {
  background-color: rgba(255,109,0,0.5);
}

.modal-content {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#intercom-container .intercom-launcher, body>.intercom-container {
  z-index: 2140!important;

}
#intercom-container .intercom-launcher{
  right:-40px!important;
}

.sparkline-chart[bordered] .highcharts-container {
  border-bottom: 1px solid rgba(0, 120, 206, 0.19);
  border-left: 1px solid rgba(0, 120, 206, 0.19);
}

.lx-select__chosen .text-field__symbol {
  margin-top: 20px;
}

.icon--xs.icon--flat, .dropdown-link:not(.dropdown-link--is-header) i.icon--xs, .dropdown-link--is-large i.icon--xs, .dropdown-link:not(.dropdown-link--is-header) i.icon--flat, .dropdown-link:not(.dropdown-link--is-header) {
  &.icon--detail {
    position: absolute;
    right: 0px;
    margin-top: 12px;
    width: 10px !important;
    height: 10px !important;
    font-size: 8px !important;
    color: white;
    min-width: 0px;
    line-height: 11px !important;
  }

}

.bgc-transition {
  transition: background 0.3s ease;
}

.tc-transition {
  transition: color 0.3s ease;
}

.text-field--is-valid:after {
  background-color: #00E676;
}

.text-field--is-valid .text-field__label {
  color: #00E676;
}

.opacity-0 {
  opacity:0;
  transition: opacity 0.3s ease;
}
.opacity-05 {
  opacity:1;
  transition: opacity 0.3s ease;
}
.opacity-1 {
  opacity:1;
  transition: opacity 0.3s ease;
}

.step a {
  cursor: default;
  color: inherit;
  text-decoration: none;
}

.step a[href]{
  cursor:pointer;
}

.intercom-button {
  position: fixed;
  bottom: -20px;
  right: 30px;
  z-index: 2140 !important;
}

#intercom-container .intercom-launcher {
  display:none!important;
}

.senuto-logo{
  &.black {
    height: 40px;
    width: 54px;
    background: url("../images/senutologo1.png") no-repeat 0 0px;
    background-size: 100%;
  }
}

.icon--xxxl {
  height: 72px;
  width: 72px;
  line-height: 72px!important;
  font-size: 40px;
}