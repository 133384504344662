///*------------------------------------*\
//    #OBJECTS-Z-DEPTH
//\*------------------------------------*/

// Z-depth classes
@for $i from 1 through 5 {
    .z-depth#{$i} {
        @include z-depth($i);
    }
}
