///*------------------------------------*\
//    #OBJECTS-DATE-PICKER
//\*------------------------------------*/

// Lx date base styles
.lx-date {
    position: relative;
}

.lx-date--fixed-label {

    // Text field
    .text-field {
        padding-top: $base-spacing-unit;
    }

    .text-field--is-active {
        .text-field__label {
            display: none;
        }
    }

        // Text field label
        .text-field__label {
            @include transform(none);
        }
}

.lx-date--with-icon {
    margin-left: $base-spacing-unit * 8;
}





// Date filter
.lx-date-filter {
    @include position(fixed, 0 0 0 0);
    opacity: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, .6);
    @include transition-property(opacity);
    @include transition-duration(.6s);
    @include transition-timing-function($ease-out-quint);
}

.lx-date-filter--is-shown {
    opacity: 1;
}





// Date input
.lx-date__input-wrapper {
    position: relative;

    &:hover {
        .lx-date__clear {
            opacity: 1;
        }
    }
}

.lx-date-input {
    &,
    input {
        cursor: pointer !important;
    }
}

.lx-date__clear {
    display: block;
    @include position(absolute, null 0 $base-spacing-unit null);
    opacity: 0;
    cursor: pointer;
    line-height: $base-spacing-unit * 4;
    @include transition-property(opacity, color);
    @include transition-duration(.2s);

    &:hover {
        color: $red-500;
    }
}





// Date picker base styles
.lx-date-picker {
    display: none;
    @include position(fixed, $base-spacing-unit * 4 null null 50%);
    opacity: 0;
    z-index: 1000;
    margin-left: -140px;
    background-color: $white;
    padding-top: 172px;
    width: 280px;
    @include z-depth(3);
    @include transform(translateY(-50px));
    @include transition-property(opacity, transform);
    @include transition-duration(.6s);
    @include transition-timing-function($ease-out-quint);
}

.lx-date-picker--is-shown {
    opacity: 1;
    @include transform(translateY(0));
}

.lx-date-picker__header {
    @include position(absolute, 0 0 null 0);
}

    // Date picker current day of week
    .lx-date-picker__current-day-of-week {
        background-color: $teal-700;

        span {
            display: block;
            @include font-size(14px);
            text-align: center;
            text-transform: capitalize;
            line-height: 32px;
            color: $white-1;
        }
    }

    // Date picker current date
    .lx-date-picker__current-date {
        background-color: $teal-500;
        padding: $base-spacing-unit 0;

        span,
        strong,
        a {
            display: block;
            text-align: center;
            font-weight: 400;
        }

        span,
        a {
            @include font-size(24px);
            line-height: 32px;
        }

        span {
            text-transform: uppercase;
        }

        strong {
            @include font-size(60px);
            line-height: 1;
        }

        a {
            cursor: pointer;
            font-style: normal;
        }
    }

    // Date picker navigation
    .lx-date-picker__nav {
        position: relative;

        span {
            display: block;
            @include font-size(14px);
            text-align: center;
            text-transform: capitalize;
            line-height: 40px;
            font-weight: 500;
        }

        button {
            position: absolute !important;
            top: $base-spacing-unit;

            &:first-child {
                left: $base-spacing-unit * 2;
            }

            &:last-child {
                right: $base-spacing-unit * 2;
            }
        }
    }

    // Date picker days of week
    .lx-date-picker__days-of-week {
        padding: 0 $base-spacing-unit * 2;

        span {
            display: inline-block;
            width: percentage(1/7);
            vertical-align: top;
            @include font-size(12px);
            text-align: center;
            text-transform: uppercase;
            line-height: 24px;
            color: $black-2;
            font-weight: 400;
        }
    }

    // Date picker days
    .lx-date-picker__days {
        padding: 0 $base-spacing-unit * 2;
    }

    // Date picker day
    .lx-date-picker__day {
        display: inline-block;
        padding: $base-spacing-unit / 2 0;
        width: percentage(1/7);
        vertical-align: top;

        a {
            display: block;
            @include size(32px);
            margin: 0 auto;
            border-radius: 50%;
            @include font-size(12px);
            text-align: center;
            line-height: 32px;
            font-weight: 400;
        }
    }

    .lx-date-picker__day--is-today a {
        color: $teal-700;
        font-weight: 500;
    }

    .lx-date-picker__day a:hover,
    .lx-date-picker__day--is-selected a {
        background-color: $teal-500;
        cursor: pointer;
        color: $white-1;
    }

    // Year selector
    .lx-date-picker__year-selector {
        position: relative;
        height: 5 * 65px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .lx-date-picker__year {
        display: block;
        cursor: pointer;

        span {
            display: block;
            @include size(65px);
            margin: 0 auto;
            border-radius: 50%;
            @include font-size(16px);
            text-align: center;
            line-height: 65px;
        }
    }

    .lx-date-picker__year:hover {
        span {
            color: $teal-700;
        }
    }

    .lx-date-picker__year--is-active {
        span {
            background-color: $teal-500;
            color: $white-1 !important;
        }
    }

    // Date picker actions
    .lx-date-picker__actions {
        border-top: 1px solid $black-4;
        padding: $base-spacing-unit;
        text-align: center;
    }
