///*------------------------------------*\
//    #BASE-IMAGES
//\*------------------------------------*/

// 1. Fluid images for responsive purposes.
// 2. Offset `alt` text from surrounding copy.
img {
    display: block;
    max-width: 100%; // [1]
    font-style: italic; // [2]
}





// 1. Google Maps breaks if `max-width: 100%` acts upon it; use their selector
//    to remove the effects.
// 2. If a `width` and/or `height` attribute have been explicitly defined, let’s
//    not make the image fluid.
.gm-style img, // [1]
img[width], // [2]
img[height] {  // [2]
    max-width: none;
}





// Rounded image helper
.img-round {
    border-radius: 50%;
}