///*------------------------------------*\
//    #OBJECTS-NOTIFICATION
//\*------------------------------------*/

// Notification base styles
.notification {
    @include position(fixed, null $base-spacing-unit * 3 0 null);
    z-index: 1001;
    max-width: 300px;
    padding: $base-spacing-unit * 2 $base-spacing-unit * 3;
    border-radius: $base-round;
    background-color: #323232;
    cursor: pointer;
    @include transform(translateY(100%));
    @include transition-property(margin-bottom transform);
    @include transition-duration(0.4s);
    @include transition-timing-function($ease-out-quint);
}

.notification--is-shown {
    @include transform(translateY(-24px));
}

    // Notification content / icon
    .notification__content,
    .notification__icon {
        opacity: 0;
        @include transition-property(opacity);
        @include transition-duration(0.4s);

        .notification--is-shown & {
            opacity: 1;
        }
    }

    // Notification content
    .notification__content {
        display: block;
        @include font-size(14px);
        color: $white-1;
        line-height: 20px;
    }

    .notification--has-icon .notification__content {
        padding-left: $base-spacing-unit * 4;
    }

    // Notification icon
    .notification__icon {
        @include position(absolute, $base-spacing-unit * 2 null null $base-spacing-unit * 3);
        @include font-size(14px);
        color: $white-1;
        line-height: 20px !important;
    }


@each $key, $color in $colors {
    .notification--#{$key} .notification__icon {
        color: $color;
    }
}
