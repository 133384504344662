/*------------------------------------*\
   #OBJECTS-SWITCH
\*------------------------------------*/

// Switch base styles
.switch {
    min-width: 35px;
    min-height: $size-xs;
}

@each $key, $color in $colors {
    .switch--#{$key} .switch__input:checked + .switch__label {
        &:before {
            background-color: rgba($color, 0.5);
        }

        &:after {
            background-color: $color;
        }
    }
}

    // Checkbox input
    .switch__input {
        @extend %visuallyhidden;

        &:not(:checked) {
            & + .switch__label {
                &:before {
                    background-color: $black-3;
                }

                &:after {
                    background-color: $grey-50;
                }
            }
        }

        &:checked {
            & + .switch__label {
                &:before {
                    background-color: rgba($accent, 0.5);
                }

                &:after {
                    background-color: $accent;
                    @include transform(translateX(15px));
                }
            }
        }

        &[disabled] {
            & + .switch__label {
                cursor: not-allowed;
                color: $black-3;

                &:before {
                    background-color: $black-4;
                }

                &:after {
                    background-color: $grey-400;
                }
            }

            & ~ .switch__help {
                color: $black-3;
            }
        }
    }

    // Switch label
    .switch__label {
        display: block;
        position: relative;
        padding-left: 45px;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
        @include user-select(none);

        .switch--standalone & {
            padding-left: 0;
        }

        &:before {
            content: '';
            @include position(absolute, 5px null null 0);
            z-index: 1;
            @include size(35px 14px);
            border-radius: 7px;
            @include transition-property(background-color);
            @include transition-duration(0.2s);
        }

        &:after {
            content: '';
            @include position(absolute, 2px null null 0);
            z-index: 2;
            display: block;
            @include size(20px);
            border-radius: 50%;
            @include z-depth(1);
            @include transition-property(background-color, transform);
            @include transition-duration(0.2s);
        }
    }

    // Switch help
    .switch__help {
        display: block;
        padding-left: 45px;
        @include typography('body-1');
        color: $black-2;
        text-align: left;
    }
