///*------------------------------------*\
//    #OBJECTS-DROPDOWN
//\*------------------------------------*/

// Dropdown base styles
.dropdown {
    position: relative;
    display: inline-block;
    vertical-align: top;
}





// Dropdown menu
.dropdown-menu {
    position: absolute;
    z-index: 1000;
    border-radius: $base-round;
    background-color: $white;
    text-align: left;
    opacity: 0;
    overflow: hidden;
    @include z-depth(1);

    ul {
        @extend %bare-list;

        & > li {
            position: relative;
        }
    }
}

.dropdown__menu--is-dropped {
    .dropdown-menu__content {
        opacity: 1;
    }
}

    // Dropdown menu content
    .dropdown-menu__content {
        padding: $base-spacing-unit 0;
        opacity: 0;
        @include transition-property(opacity);
        @include transition-duration(0.2s);
    }





// Dropdown divider
.dropdown-divider {
    height: 1px;
    margin-top: $base-spacing-unit;
    margin-bottom: $base-spacing-unit;
    background-color: $black-4;
}





// Dropdown link
.dropdown-link {
    @include display(flex);
    @include flex-direction(row);
    @include align-items(center);
    height: 32px;
    padding: 0 $base-spacing-unit * 4 0 $base-spacing-unit * 2;
    cursor: pointer;
    @include font-size(14px);
    color: $black-1;
    line-height: 32px;
    text-decoration: none;
    white-space: nowrap;

    &:not(.dropdown-link--is-header) {
        &:hover {
            background-color: $grey-200;
        }

        i {
            @extend %icon;
            @extend %icon--xs;
            @extend %icon--flat;
            margin-right: $base-spacing-unit * 2;
            color: $black-2;
        }
    }
}

.dropdown-link--is-large {
    height: 48px;

    i {
        @extend %icon;
        @extend %icon--s;
        @extend %icon--flat;
    }
}

.dropdown-link--is-header {
    color: $black-3;
    cursor: default;

    i {
        width: 18px;
        margin-right: 6px;
        color: $black-3;
        text-align: center;
    }
}
