///*------------------------------------*\
//    #TOOLS-WIDTHS
//\*------------------------------------*/

// A mixin to spit out our width classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths.

@mixin widths($widths-columns, $widths-breakpoint: null) {
    @each $widths-denominator in $widths-columns {
        @if ($widths-denominator == 1) {
            .w-1\/1#{$widths-breakpoint} {
                width: 100% !important;
            }
        } @else {
            @for $widths-numerator from 1 to $widths-denominator {
                .w-#{$widths-numerator}\/#{$widths-denominator}#{$widths-breakpoint} {
                    width: ($widths-numerator / $widths-denominator) * 100% !important;
                }
            }
        }
    }
}
