///*------------------------------------*\
//    #SETTINGS-DEFAULTS
//\*------------------------------------*/

// High-level base settings.
$base-font-size:        16px            !default;
$base-line-height:      24px            !default;
$base-text-color:       $black-1        !default;
$base-background-color: $grey-200       !default;
$base-round:            2px             !default;





// Colors and sizes
// Feel free to override and set new values for your project.

// Main colors
$red:                   $red-500            !default;
$pink:                  $pink-500           !default;
$purple:                $purple-500         !default;
$deep-purple:           $deep-purple-500    !default;
$indigo:                $indigo-500         !default;
$blue:                  $blue-500           !default;
$light-blue:            $light-blue-500     !default;
$cyan:                  $cyan-500           !default;
$teal:                  $teal-500           !default;
$green:                 $green-500          !default;
$light-green:           $light-green-500    !default;
$lime:                  $lime-500           !default;
$yellow:                $yellow-500         !default;
$amber:                 $amber-500          !default;
$orange:                $orange-500         !default;
$deep-orange:           $deep-orange-500    !default;
$brown:                 $brown-500          !default;
$grey:                  $grey-500           !default;
$blue-grey:             $blue-grey-500      !default;

// Primary palette colors and accent colors
$primary:               $indigo-500 !default;
$primary-hue-1:         $indigo-100 !default;
$primary-hue-2:         $indigo-700 !default;
$accent:                $pink-A200  !default;
$accent-hue-1:          $pink-A100  !default;
$accent-hue-2:          $pink-A400  !default;

// Sizes
$size-xs:               24px !default;
$size-s:                30px !default;
$size-m:                36px !default;
$size-l:                40px !default;
$size-xl:               56px !default;





// These variables are framework variables, sourced from variables defined
// above. Feel free to use these variables throughout your project, but do not
// modify or reassign them.
$base-spacing-unit:     8px;
$colors:                ('red': $red, 'pink': $pink, 'purple': $purple, 'deep-purple': $deep-purple, 'indigo': $indigo, 'blue': $blue, 'light-blue': $light-blue, 'cyan' : $cyan ,'teal': $teal, 'green': $green, 'light-green': $light-green, 'lime': $lime, 'yellow': $yellow, 'amber': $amber, 'orange': $orange, 'deep-orange': $deep-orange, 'brown': $brown, 'grey': $grey, 'blue-grey': $blue-grey, 'black': $black, 'white': $white, 'primary': $primary, 'primary-hue-1': $primary-hue-1, 'primary-hue-2': $primary-hue-2, 'accent': $accent, 'accent-hue-1': $accent-hue-1, 'accent-hue-2': $accent-hue-2);
$sizes:                 ('xs': $size-xs, 's': $size-s, 'm': $size-m, 'l': $size-l, 'xl': $size-xl);
